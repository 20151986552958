<template>
  <div class="header">
    <div class="member-head-top">
      <div class="container">
        <div class="row flex-wrapper mt-0 pt-3">
          <div class="header-main">
            <h1 class="header-logo"><router-link to="/">公共法律服务网</router-link></h1>
            <div class="header-title"><a href="http://www.faxuan.net/">法宣在线统一身份认证</a></div>
          </div>
          <ul class="header-aside">
            <li class="border-0">
              <router-link to="/Register" target="_blank" class="btn ant-btn-primary">注册</router-link>
            </li>
            <li class="border-0">
              <router-link to="/Login" target="_blank" class="btn ant-btn-primary">登陆</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UserHeader'
}
</script>
<style scoped src="../../assets/css/member.css"></style>
